window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}
gtag('js', new Date());

// NOTE: configからページビューの情報を送れるようなので、そこからカスタムディメンションのデータを送信するようにした https://qiita.com/k2low/items/7a25bdcc0367dc406746
if (window.const.login_user_id !== undefined) {
	gtag('config', window.const.ga_tracking_id, {
		'custom_map': {'dimension5': 'cd_user_id'},
		'cookie_name': 'gaCookie',
		'user_id': window.const.login_user_id,
		'cd_user_id': window.const.login_user_id
	})
} else {
	gtag('config', window.const.ga_tracking_id, {
		'cookie_name': 'gaCookie',
	})
}

// NOTE: ビューワー用でwindowからgtagを参照できるようにしている
window.gtag = gtag

// NOTE: .gtag-elementを指定したクラスにgtagイベントをフックしている。data-event-name, data-event-category, data-event-label を指定すること https://developers.google.com/analytics/devguides/collection/gtagjs/migration?hl=ja
window.addEventListener('DOMContentLoaded', () => {
	document.querySelectorAll('.gtag-element').forEach(element => {
		element.addEventListener('click', () => {
			const eventName = element.dataset.eventName
			const eventCategory = element.dataset.eventCategory
			const eventLabel = element.dataset.eventLabel
			gtag('event', eventName, {
				'event_category': eventCategory,
				'event_label': eventLabel
			})
		})
	})
})
